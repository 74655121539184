<template>
  <!--合同信息 模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <!--<el-form-->
        <!--    :model="where"-->
        <!--    label-width="60px"-->
        <!--    class="ele-form-search"-->
        <!--    @submit.native.prevent>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :lg="21" :md="12">-->
        <!--      <el-form-item label="服务商:">-->
        <!--        <el-select-->
        <!--            style="width: 300px !important;"-->
        <!--            clearable-->
        <!--            v-model="where.gender"-->
        <!--            placeholder="请选择服务商"-->
        <!--            class="ele-fluid">-->
        <!--          <el-option label="服务商1" value="1"/>-->
        <!--          <el-option label="服务商1" value="2"/>-->
        <!--          <el-option label="服务商 1" value="3"/>-->
        <!--        </el-select>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-form>-->
      </div>

      <div class="zhanshi" style="margin-top: 15px;">
        <el-row :gutter="15" style="margin-bottom: 20px;">
          <el-col :span="6">
            <span>结算方式:</span>
            <el-select
                clearable
                @change="changemeans_payments"
                v-model="means_payments"
                placeholder="请选择"
                class="ele-fluid">
              <el-option
                  v-for="(item) in dict.type.valuationclearing_form"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">生效日期：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.start_time}}</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约周期：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.period}}年</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">到期自动签续：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">{{shuju.is_renew}}</span>
          </el-col>
          <el-col :lg="6" :md="12">
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">签约状态：</span>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #4e4e4e;">
              <!--<span style="display: inline-block; width: 8px;height: 8px;background: #25eb61;border-radius: 50%; vertical-align: middle"></span>-->
              <span style="vertical-align: middle; margin-left: 5px;">{{shuju.status}}</span>
            </span>
          </el-col>
        </el-row>
      </div>

      <!--开票资料-->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              开票资料
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="Showageing(null)">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle">新增开票资料</span>
              </el-button>
            </div>
          </div>
        </div>
        <!-- 开票资料表格 -->
        <div style="margin-top: 15px;">
          <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :headerCellStyle="headerCellStyle"
            :total="total"
            :customsFromWhere="where"
            :loading="loading2"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
          >
            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="Showageing(scope.row)">编辑</el-link>
              <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                <el-link slot="reference" :underline="false">删除</el-link>
              </el-popconfirm>
            </template>
          </my-table>
        </div>
      </div>

      <!--签约记录-->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              签约记录
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button @click="openshowcontract()">电子合同</el-button>
              <el-button @click="openEdit(null)">更新签约</el-button>
            </div>
          </div>
        </div>
        <!-- 签约记录表格 -->
        <div style="margin-top: 15px;">
          <my-table
            ref="myTable"
            :columns="columns1"
            :tableData="List1"
            :headerCellStyle="headerCellStyle"
            :total="total1"
            :customsFromWhere="where1"
            :loading="loading1"
            @select="select1"
            @ChangeSize="ChangeSize1"
            @currentChange="currentChange1"
          >

            <!--签约周期-->
            <template v-slot:period= "{ scope }">
              <span>{{scope.row.period}} 年</span>
            </template>

            <!--到期自动续签-->
            <template v-slot:is_renew= "{ scope }">
              <span v-if="scope.row.is_renew == 1" style="color: #36DD9E;">是</span>
              <span v-else>否</span>
            </template>

            <!--合同附件-->
            <template v-slot:agreement= "{ scope }">
              <span style="color: #FF9E1C;cursor: pointer;" @click="open(scope.row)">查看</span>
            </template>


            <!-- 不使用数据可不接收scope值 -->
            <!--<template v-slot:operationSlot= "{ scope }">-->
            <!--  <el-link :underline="false" type="warning">查看</el-link>-->
            <!--</template>-->
            <!--自定义列显示 示例-->
            <template v-slot:means_payments= "{ scope }">
              <div v-for="(item) in dict.type.valuationclearing_form">
                <span v-if="scope.row.settlement_mode == item.value">{{item.label}}</span>
              </div>
            </template>
          </my-table>
        </div>
      </div>


    </el-card>

    <!--开票资料弹窗-->
    <Edit :data="currenta" :visible.sync="showEdita" :id="id" :dict="dict" @done="getList"/>

    <!-- 更新签约弹窗 -->
    <subscription-info :data="current" :id="id" :dict="dict" :visible.sync="showEdit" @done="getList1"/>

    <!--合同附件-->
    <el-dialog
      center
      title="合同附件"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <div v-for="(item,index) in appendixtothecontract" :key="index" style="cursor: pointer;margin-bottom: 10px;" >
         <span> {{item}}</span>
          <span style="margin-left: 20px;" @click="fileshow(item)">下载</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!--<el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      </span>
    </el-dialog>
    <!--查看-->
    <el-dialog
      center
      :visible.sync="dialogVisible1"
      width="30%"
      :before-close="handleClose1">
      <div>
        查看
      </div>
    </el-dialog>


    <!--电子合同-->
    <el-dialog
      center
      title="电子合同"
      :visible.sync="showcontract"
      width="60%"
      :before-close="closecontract">
      <div style="margin-bottom: 20px;">
        <el-button type="primary" @click="Toelectroniccontract(null,'创建')">创建模板</el-button>
        <!--<el-button type="primary" @click="Toelectroniccontract(null,'印章列表')">印章列表</el-button>-->
      </div>
      <div>
        <my-table
          ref="myTable"
          :columns="columns2"
          :tableData="List2"
          :headerCellStyle="headerCellStyle"
          :loading="loading"
          @select="select2"
        >
          <!--模板名称-->
          <template v-slot:templateName= "{ scope }">
            <div style="text-align: left !important;">
              {{scope.row.templateName}}
            </div>
            <div style="text-align: left !important;">
              {{scope.row.templateId}}
            </div>
          </template>
          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-link :underline="false" type="warning" @click="Toelectroniccontract(scope.row,'使用')">使用</el-link>
            <el-link :underline="false" type="success" @click="Toelectroniccontract(scope.row,'编辑')">编辑</el-link>
            <el-link :underline="false" type="info" @click="Toelectroniccontract(scope.row,'预览')">预览</el-link>
          </template>
        </my-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--<el-button @click="dialogVisible = false">取 消</el-button>-->
        <!--<el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      </span>
    </el-dialog>

    <!--合同信息-->
    <el-dialog
      center
      title="合同信息"
      :visible.sync="showcontractinfo"
      width="70%"
      :before-close="closecontractinfo">
      <div>
        <el-form
          ref="form"
          :model="form"
          label-width="180px">
          <el-form-item label="合同名称:" prop="flowName">
            <el-input
              clearable
              v-model="form.flowName"
              placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="合同描述信息:" prop="flowDescription">
            <el-input
              clearable
              v-model="form.flowDescription"
              placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="签署截至日期:" prop="deadline">
            <el-date-picker
              v-model="form.deadline"
              type="date"
              value-format="yyyy-MM-dd"
              @change="handleChange"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-row :gutter="15">
            <el-col :span="12">
              <div style="box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);padding: 10px;padding-top: 20px;">
                <div>甲方</div>
                <el-form-item label="企业名称:" prop="organizationName">
                  <el-input
                    clearable
                    v-model="form.flowApproverInfo[0].organizationName"
                    placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="签署人名称:" prop="approveName">
                  <el-input
                    clearable
                    v-model="form.flowApproverInfo[0].approveName"
                    placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="签署人手机号:" prop="phoneNumber">
                  <el-input
                    clearable
                    v-model="form.flowApproverInfo[0].phoneNumber"
                    placeholder="请输入"/>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);padding: 10px;padding-top: 20px;">
                <div>乙方</div>
                <el-form-item label="企业名称:" prop="organizationName">
                  <el-input
                    clearable
                    v-model="form.flowApproverInfo[1].organizationName"
                    placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="签署人名称:" prop="approveName">
                  <el-input
                    clearable
                    v-model="form.flowApproverInfo[1].approveName"
                    placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="签署人手机号:" prop="phoneNumber">
                  <el-input
                    clearable
                    v-model="form.flowApproverInfo[1].phoneNumber"
                    placeholder="请输入"/>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <div style="box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);padding: 10px;padding-top: 20px;margin-top: 20px;">
            <el-form
              ref="form"
              :model="form1"
              label-width="80px">
              <el-row :gutter="18">
                <el-col :span="24">
                  <el-form-item label="生效日期:" prop="commencement_date">
                    <el-date-picker
                      v-model="form1.commencement_date"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="签约周期:" prop="period">
                    <el-input placeholder="请输入" v-model="form1.period">
                      <template slot="append">.年</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="自动续签:" prop="is_renew">
                    <el-select
                      clearable
                      v-model="form1.is_renew"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option label="是" value="0"/>
                      <el-option label="否" value="1"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="结算方式:" prop="means_payments">
                    <el-select
                      clearable
                      v-model="form1.means_payments"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.valuationclearing_form"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao()">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
//引入axios 为了电子合同
import axios from 'axios';
// 获取下电子合同url
import Config from '@/config/setting'

// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Configconfig from '@/config'

// 引入更新签约弹窗
import SubscriptionInfo from './components/subscription-info.vue';
import Edit from './components/edit.vue'

// 引入的接口
import {
  Deldel_invoice,
  getreconciliation,
  getcityList,
  getinvoice,
  query_signatory,
  save_electron_contract_award, get_electron_contract_award_url, put_contract_award
} from "@/api/yunli";
import Vue from "vue";

export default {
  components:{
    Edit,
    SubscriptionInfo
  },
  props: {
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
    // 字典数据
    // dict:Object,
  },
  data(){
    return{
      //条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },

      // 更细签约数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,

      currenta:null,
      showEdita:false,

      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "公司名称",
          prop: "company_name",
          isShow: true
        },
        {
          label: "开票税号",
          prop: "tax_number",
          isShow: true
        },
        {
          label: "开户银行",
          prop: "deposit_bank",
          isShow: true
        },
        {
          label: "银行账号",
          prop: "bank_number",
          isShow: true
        },
        {
          label: "电话",
          prop: "phone",
          isShow: true
        },
        {
          label: "企业地址",
          prop: "enterprise_address",
          isShow: true
        },
        {
          label: "邮寄地址",
          prop: "delivery_address",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 签约表格数据
      where1:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      List1: [],
      // 总条目数
      total1: 0,
      //加载动画
      loading1:false,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "生效日期",
          prop: "start_time",
          isShow: true
        },
        {
          label: "签约周期",
          prop: "period",
          slot:'period',
          isShow: true
        },
        {
          label: "到期自动续签",
          prop: "is_renew",
          slot:'is_renew',
          isShow: true
        },
        {
          label: "合同附件",
          prop: "agreement",
          slot:'agreement',
          isShow: true
        },
        {
          label: "操作时间",
          prop: "operating_time",
          isShow: true
        },
        {
          label: "操作人",
          prop: "operating_user",
          isShow: true
        },
        {
          label: "结算方式",
          width: "200",
          prop: 'means_payments',
          slot: "means_payments",
          isShow: true
        },
      ],
      //合同附件
      dialogVisible:false,
      appendixtothecontract:[],
      dialogVisible1:false,

      shuju:{},

      pid:0,

      //电子合同
      showcontract:false,
      loading2:false,
      // 表头数据
      columns2: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "模板名称",
          slot:'templateName',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],
      List2:[],

      // 合同信息
      showcontractinfo:false,
      // 合同信息数据
      contractinfodata:null,
      // 合同信息的表单
      deadline: 0,
      form:{
        flowName:'',
        flowDescription:'',
        deadline:'',
        flowApproverInfo:[
          {
            approverType: "ORGANIZATION",
            organizationName: "",
            approveName: "",
            phoneNumber: "",
            // openId: "",
            // organizationOpenId: "",
            recipientId: "",
            isPromoter: "1"//（是否发起方：0是1否）
          },
          {
            approverType: "ORGANIZATION",
            organizationName: "",
            approveName: "",
            phoneNumber: "",
            recipientId: "",
            isPromoter: "1"//（是否发起方：0是1否）
          },
        ]
      },

      // 甲方
      firstparty:[],
      // 甲方签署
      firstpartyqianshu:[],
      // 乙方
      partyB:[],
      // 乙方签署
      partyBqianshu:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Configconfig.tokenStoreName)
      },
      fileList:[],
      showshangchuan:false,
      shangchuanurl:'',
      upload_btn: false, // 是否隐藏上传图片按钮

      //附件上传
      fileList1:[],
      duowenjian:[],

      //城市列表
      city:[],

      //企业印章
      seal:[],
      isShow:0,
      isShow1:0,

      //企业id
      orgOpenId:'',
      openId:'',

      //
      form1:{},

      // 合同id
      flowIds:'',

      //字典
      dict: {
        type:{}
      },

      //结算方式
      means_payments:'',

    }
  },

  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '运力字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    console.log('合同信息父级id',this.id);
    this.getList();
    this.getList1();
    this.getCity();
  },

  methods:{
    // 结算方式选择
    changemeans_payments(value){
      console.log(value)
      let data = {
        means_payments: value
      }
      put_contract_award(this.id,data).then(res => {
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList1();
        }else{
          this.$message.error(res.msg);
        }
      })
    },

    // 获取列表
    getList(){
      getinvoice(this.id,this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$message.error(res.msg);
        this.loading = false;
      })
    },

    getList1(){
      getreconciliation(this.id,this.where).then(async (res) => {
        console.log('合同信息列表',res);
        //签约记录
        // this.total1 = res.data.count;
        this.shuju = res.data;
        this.List1 = res.data.contract_award;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    Showageing(row){
      this.currenta = row;
      this.showEdita = true;
    },
    // 点击更新签约按钮
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 删除
    del(row){
      let where = {
        id: row.id
      }
      Deldel_invoice(this.id,where).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },


    //签约记录表格事件
    // 当前页数改变事件
    currentChange1(val) {
      console.log("当前页数", val)
      this.where1.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select1(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize1(val){
      console.log('当前页面显示的条数',val);
      this.where1.limit = val;
      this.getList()
    },

    //合同附件查看
    open(row){
      console.log(row);
      this.appendixtothecontract = [];
      // 判断下是否是下载电子合同的附件  1是电子合同 0是更新签约
      if(row.is_electron == 1){
        get_electron_contract_award_url(row.id).then(res => {
          console.log(res);
          this.appendixtothecontract.push(res.data.url);
        })
      }else {
        this.appendixtothecontract = row.agreement;
      }
      this.dialogVisible = true;
    },
    // 合同附件关闭
    handleClose(){
      this.dialogVisible = false;
    },
    // 附件下载
    fileshow(item){
      window.open(item);
      this.dialogVisible = false;
      this.getList1();
      // this.dialogVisible1 = true;
      // this.dialogVisible = false;
    },
    // 合同附件查看关闭
    handleClose1(){
      this.dialogVisible1 = false;
      this.dialogVisible = true;
    },

    //点击电子合同
    openshowcontract(){
      // 查询服务商与服务商合作接口
      query_signatory(this.id).then(res => {
        // console.log(res);
        // if(res.data.first_signatory == false){
        //   this.$message.error('本企业合同未认证');
        // }
        // if(res.data.second_signatory == false){
        //   setTimeout(() => {
        //     this.$message.error('合作伙伴合同未认证');
        //   },1000)
        // }
        // // 判断如何都为true则为都开通了
        // if(res.data.first_signatory == true && res.data.second_signatory == true){
        //   console.log('url',Config.contracturl)
        //
        //   // 获取到企业id
        //   this.orgOpenId = res.data.orgOpenId;
        //   // 经办人id
        //   this.openId = res.data.openId;
        //
        //   // 查询模板信息
        //   axios({
        //     method: 'POST',
        //     url: Config.contracturl,
        //     data:{
        //       name: 'DescribeTemplates',
        //       data: {
        //         // agent: {
        //         //   // 企业id
        //         //   organizationOpenId: 'b65471dc-c71a-46c7-b4a4-72da8217b87c',
        //         //   operator:{
        //         //     // 经办人id
        //         //     openId: '21d39e9f-6828-4485-9a4f-6872089eaec8'
        //         //   }
        //         // },
        //         agent: {
        //           // 企业id
        //           organizationOpenId: this.orgOpenId,
        //           operator:{
        //             // 经办人id
        //             openId: this.openId
        //           }
        //         },
        //         limit: 100
        //       },
        //     }
        //   }).then(res => {
        //     console.log('电子合同',res)
        //     this.$message.info(res.data.data.msg)
        //     // 获取模板信息列表
        //     this.List2 = res.data.data.data.templateInfo;
        //     this.loading2 = false;
        //     // 显示弹窗
        //     this.showcontract = true;
        //   })
        //
        // }

        //合同名称
        this.form.flowName = res.data.contract_name;
        //合同描述
        this.form.flowDescription = res.data.contract_des;
        //截至日期
        this.form.deadline = res.data.contract_deadline;
        // 甲方信息
        this.form.flowApproverInfo[0].organizationName = res.data.firstInfo.company_name;
        this.form.flowApproverInfo[0].approveName = res.data.firstInfo.realname;
        this.form.flowApproverInfo[0].phoneNumber = res.data.firstInfo.phone;
        //乙方信息
        this.form.flowApproverInfo[1].organizationName = res.data.secondInfo.company_name;
        this.form.flowApproverInfo[1].approveName = res.data.secondInfo.realname;
        this.form.flowApproverInfo[1].phoneNumber = res.data.secondInfo.phone;

        // 获取到企业id
        this.orgOpenId = res.data.orgOpenId;
        // 经办人id
        this.openId = res.data.openId;
        // 查询模板信息
        axios({
          method: 'POST',
          url: Config.contracturl,
          data:{
            name: 'DescribeTemplates',
            data: {
              // agent: {
              //   // 企业id
              //   organizationOpenId: 'b65471dc-c71a-46c7-b4a4-72da8217b87c',
              //   operator:{
              //     // 经办人id
              //     openId: '21d39e9f-6828-4485-9a4f-6872089eaec8'
              //   }
              // },
              agent: {
                // 企业id
                // organizationOpenId: this.orgOpenId,
                operator:{
                  // 经办人id
                  openId: this.openId
                }
              },
              limit: 100
            },
          }
        }).then(res => {
          console.log('电子合同',res)
          // this.$message.info(res.data.data.msg)
          // 获取模板信息列表
          this.List2 = res.data.data.data.templateInfo;
          this.loading2 = false;
          // 显示弹窗
          this.showcontract = true;
        })
      })

      // 查询模板信息  测试用
      // axios({
      //   method: 'POST',
      //   url: Config.contracturl,
      //   data:{
      //     name: 'DescribeTemplates',
      //     data: {
      //       // agent: {
      //       //   // 企业id
      //       //   organizationOpenId: 'b65471dc-c71a-46c7-b4a4-72da8217b87c',
      //       //   operator:{
      //       //     // 经办人id
      //       //     openId: '21d39e9f-6828-4485-9a4f-6872089eaec8'
      //       //   }
      //       // },
      //       agent: {
      //         // 企业id
      //         organizationOpenId: 'd5e99385-122a-4dec-b70a-1aa2f24b57cb',
      //         operator:{
      //           // 经办人id
      //           openId: 'd5e99385-122a-4dec-b70a-1aa2f24b57cb'
      //         }
      //       },
      //       limit: 100
      //     },
      //   }
      // }).then(res => {
      //   console.log('电子合同',res)
      //   this.$message.info(res.data.data.msg)
      //   // 获取模板信息列表
      //   this.List2 = res.data.data.data.templateInfo;
      //   this.loading2 = false;
      //   // 显示弹窗
      //   this.showcontract = true;
      // })
    },
    //关闭电子合同弹窗
    closecontract(){
      this.showcontract = false;
    },
    // 电子合同点击列表数据
    select2(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    // 点击电子合同的操作
    Toelectroniccontract(row,value){
      if(value == '使用'){
        // this.form = {
        //   deadline:'',
        //   flowApproverInfo:[
        //     {
        //       approverType: "ORGANIZATION",
        //       organizationName: "",
        //       approveName: "",
        //       phoneNumber: "",
        //       // openId: "",
        //       // organizationOpenId: "",
        //       recipientId: "",
        //       isPromoter: "1"//（是否发起方：0是1否）
        //     },
        //     {
        //       approverType: "ORGANIZATION",
        //       organizationName: "",
        //       approveName: "",
        //       phoneNumber: "",
        //       recipientId: "",
        //       isPromoter: "1"//（是否发起方：0是1否）
        //     },
        //   ]
        // }
        // 显示合同信息
        this.showcontractinfo = true;
        // 合同信息的数据
        this.contractinfodata = row;
        // 甲方
        this.firstparty = [];
        this.firstpartyqianshu = [];
        // 乙方
        this.partyB = [];
        this.partyBqianshu = [];
        console.log(row.components);
        // 表单控件
        row.components.forEach(item => {
          // 目前判断不知为何是反这来的
          if(item.componentName.indexOf('甲方')){
            // console.log('乙方',item);
            this.partyB.push(item);
          }else if(item.componentName.indexOf('乙方')){
            // console.log('甲方',item);
            this.firstparty.push(item);
            // 选择器组件的单独处理
            this.firstparty.forEach(res => {
              if(res.componentType == 'SELECTOR'){
                res.option = JSON.parse(res.componentExtra);
                // console.log(res.option)
              }
            })
          }
          //每次清空数据
          item.componentValue = '';
        })
        // 签署控件
        row.signComponents.forEach(item => {
          // 目前判断不知为何是反这来的
          if(item.componentName.indexOf('甲方') && item.componentName!= '签署日期' && item.componentName!= '签署日期1'){
            console.log('乙方',item);
            this.partyBqianshu.push(item);
          }else if(item.componentName.indexOf('乙方') && item.componentName!= '签署日期' && item.componentName!= '签署日期1'){
            console.log('甲方',item);
            this.firstpartyqianshu.push(item);
          }
          //每次清空数据
          item.componentValue = '';
        })
        // 印章的日期
        row.signComponents.forEach(item => {
          if(item.componentName == '签署日期'){
            this.firstpartyqianshu.push(item);
          }
          if(item.componentName == '签署日期1'){
            this.partyBqianshu.push(item);
          }
        })

        console.log('甲方,',this.firstparty);
        console.log('乙方',this.partyB);
        console.log('甲方签署,',this.firstpartyqianshu);
        console.log('乙方签署',this.partyBqianshu);

        //查询企业印章
        // axios({
        //   method: 'POST',
        //   url: Config.contracturl,
        //   data:{
        //     name: 'ChannelDescribeOrganizationSeals',
        //     data: {
        //       agent: {
        //         // // 企业id
        //         // organizationOpenId: '2317490490',
        //         // operator:{
        //         //   // 经办人id
        //         //   openId: '1492d94f-8b3a-484f-80ba-9ec705a52ee7'
        //         // }
        //         // 企业id
        //         organizationOpenId: this.orgOpenId,
        //         operator:{
        //           // 经办人id
        //           openId: this.openId
        //         }
        //       },
        //       // agent: {
        //       //   // 企业id
        //       //   organizationOpenId: '2329691410',
        //       //   operator:{
        //       //     // 经办人id
        //       //     openId: 'b65471dc-c71a-46c7-b4a4-72da8217b87c'
        //       //   }
        //       // },
        //       limit:100
        //     },
        //   }
        // }).then(res => {
        //   console.log('查询企业印章',res)
        //   this.seal = res.data.data.data.seals;
        // })

      }else {
        // 编辑和预览都是跳转页面
        let data = {
          row:row,
          value: value,
          orgOpenId: this.orgOpenId,
          openId: this.openId
        }
        // window.open('/electroniccontract')
        // 跳转并传递参数
        let pathHerf = this.$router.resolve({
          path:'/electroniccontract', // 你自己要跳转的路径
          query:{
            param:JSON.stringify(data)  //其中list是一个数组或者对象
          }
        })
        window.open(pathHerf.href, '_blank')
      }

    },

    //关闭合同信息
    closecontractinfo(){
      this.showcontractinfo = false;
    },

    //签署截至日期
    handleChange(value){
      // 将指定日期转换为时间戳
      let date = new Date(value);
      let timestamp2 = date.getTime();
      this.deadline = timestamp2
      console.log(this.deadline)
    },

    // 点击确定
    save(){
      let flowInfo = this.form;
      // 签署截至日期
      // 将指定日期转换为时间戳
      let date = new Date(this.form.deadline);
      let timestamp2 = date.getTime();
      this.deadline = timestamp2
      console.log(this.deadline)
      flowInfo.deadline = this.deadline / 1000;

      // 发起方
      flowInfo.flowApproverInfo[0].recipientId = this.contractinfodata.recipients[0].recipientId;
      // 企业id
      // flowInfo.flowApproverInfo[0].organizationOpenId = '2317490490';
      // flowInfo.flowApproverInfo[0].organizationOpenId = this.orgOpenId;
      // 经办人id
      // flowInfo.flowApproverInfo[0].openId = '1492d94f-8b3a-484f-80ba-9ec705a52ee7';
      // flowInfo.flowApproverInfo[0].openId = this.openId;


      flowInfo.flowApproverInfo[1].recipientId = this.contractinfodata.recipients[1].recipientId;
      flowInfo.templateId = this.contractinfodata.templateId;
      // console.log('flowInfo',flowInfo);
      // console.log('row',this.contractinfodata)


      console.log('甲方',this.firstparty);
      console.log('甲方签署',this.firstpartyqianshu);
      console.log('乙方',this.partyB);
      console.log('乙方签署',this.partyBqianshu);
      let mergedArray  = [];
      this.firstparty.forEach(item => {
        mergedArray.push(item)
      })
      this.firstpartyqianshu.forEach(item => {
        mergedArray.push(item)
      })
      this.partyB.forEach(item => {
        mergedArray.push(item)
      })
      this.partyBqianshu.forEach(item => {
        mergedArray.push(item)
      })
      console.log(mergedArray)
      // mergedArray.forEach(item => {
      //   if(item.componentType == 'DATE' || item.componentType == 'SIGN_DATE'){
      //     console.log('日期',item)
      //   }
      // })

      flowInfo.components = mergedArray;

      // 使用模板发起签署流程
      axios({
        method: 'POST',
        url: Config.contracturl,
        data:{
          name: 'CreateFlowsByTemplates',
          data: {
            // agent: {
            //   // 企业id
            //   organizationOpenId: '2317490490',
            //   operator:{
            //     // 经办人id
            //     openId: 'c22679dd-f48f-45d4-a04e-3c0ea88e4e44'
            //   }
            // },
            agent: {
              // organizationOpenId: this.orgOpenId,
              operator:{
                // 经办人id
                openId: this.openId
              }
            },

            flowInfo: flowInfo
          },
        }
      }).then(res => {
        console.log('发起合同',res)
        if(res.data.data.code == '0'){
          // 合同id
          this.flowIds = res.data.data.data.flowIds[0];
          // 调用电子签约接口
          let data = {
            commencement_date: this.form1.commencement_date,
            period: this.form1.period,
            is_renew: this.form1.is_renew,
            means_payments: this.form1.means_payments,
            id: this.id,
            ht_id: this.flowIds
          }
          save_electron_contract_award(data).then(reslut => {
            console.log(reslut,'reslut')
            if(reslut.code == 200){
              this.$message.success('创建合同成功，请注意手机短信');
              this.form1 = {};
              // 关闭填写弹窗
              this.showcontractinfo = false;
              this.getList1();
              this.getList();
            }
          })
        }else {
          // 查询服务商与服务商合作接口
          query_signatory(this.id).then(res => {
            //合同名称
            this.form.flowName = res.data.contract_name;
            //合同描述
            this.form.flowDescription = res.data.contract_des;
            //截至日期
            this.form.deadline = res.data.contract_deadline;
            // 甲方信息
            this.form.flowApproverInfo[0].organizationName = res.data.firstInfo.company_name;
            this.form.flowApproverInfo[0].approveName = res.data.firstInfo.realname;
            this.form.flowApproverInfo[0].phoneNumber = res.data.firstInfo.phone;
            //乙方信息
            this.form.flowApproverInfo[1].organizationName = res.data.secondInfo.company_name;
            this.form.flowApproverInfo[1].approveName = res.data.secondInfo.realname;
            this.form.flowApproverInfo[1].phoneNumber = res.data.secondInfo.phone;

            // 获取到企业id
            this.orgOpenId = res.data.orgOpenId;
            // 经办人id
            this.openId = res.data.openId;
            // 查询模板信息
            axios({
              method: 'POST',
              url: Config.contracturl,
              data:{
                name: 'DescribeTemplates',
                data: {
                  agent: {
                    // 企业id
                    // organizationOpenId: this.orgOpenId,
                    operator:{
                      // 经办人id
                      openId: this.openId
                    }
                  },
                  limit: 100
                },
              }
            }).then(res => {
              console.log('电子合同',res)
              // this.$message.info(res.data.data.msg)
              // 获取模板信息列表
              this.List2 = res.data.data.data.templateInfo;
              this.loading2 = false;
            })
          })
          this.$message.error(res.data.data.msg);
        }
      })
    },

    //取消
    quxiao(){
      this.form1 = {};
      this.showcontractinfo = false;
    },




    //上传
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.shangchuanurl = file.url;
      this.showshangchuan = true;
    },
    change(){
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(obj,file,fileList){
      console.log('obj',obj.data)
      console.log(file);
      console.log(fileList)
      // 上传结构
      let UploadFile = {
        fileUrl: fileList.response.data.urls
      }
      // 文件上传
      axios({
        method: 'POST',
        url: Config.contracturl,
        data:{
          name: 'UploadFiles',
          data: {
            // agent: {
            //   // 企业id
            //   organizationOpenId: '2317490490',
            //   operator:{
            //     // 经办人id
            //     openId: '1492d94f-8b3a-484f-80ba-9ec705a52ee7'
            //   }
            // },
            agent: {
              organizationOpenId: this.orgOpenId,
              operator:{
                // 经办人id
                openId: this.openId
              }
            },
            businessType: 'DOCUMENT',
            uploadFile: [UploadFile]
          },
        }
      }).then(res => {
        console.log('文件上传',res)
        obj.data.componentValue = res.data.data.data.fileIds[0]
      })
    },

    // 附件上传
    handleRemove1(file, fileList) {
      console.log(file, fileList);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    submitUpload(item){
      console.log('点击上传')
      if(this.duowenjian.length == 0){
        this.$message.error('请先选择文件再上传!')
      }else {
        // 文件上传
        axios({
          method: 'POST',
          url: Config.contracturl,
          data:{
            name: 'UploadFiles',
            data: {
              // agent: {
              //   // 企业id
              //   organizationOpenId: '2317490490',
              //   operator:{
              //     // 经办人id
              //     openId: '1492d94f-8b3a-484f-80ba-9ec705a52ee7'
              //   }
              // },
              agent: {
                organizationOpenId: this.orgOpenId,
                operator:{
                  // 经办人id
                  openId: this.openId
                }
              },
              businessType: 'DOCUMENT',
              uploadFile: this.duowenjian
            },
          }
        }).then(res => {
          console.log('附件上传',res)
          if(res.data.data.code == '0'){
            this.$message.success('上传成功!');
            item.componentValue = res.data.data.data.fileIds.join(',')//变成字符串后，以逗号分隔
          }else {
            this.$message.error('上传失败!')
          }
        })
      }
    },
    //上传成功
    successUpload1(obj,file,fileList){
      console.log('obj',obj.data)
      console.log(file);
      console.log(fileList)
      // 上传结构
      let UploadFile = {
        fileUrl: fileList.response.data.urls
      }
      // 用于传递给合同
      this.duowenjian.push(UploadFile);
    },

    //级联选择器
    cascaderhandleChange(obj,value) {
      // console.log('obj',obj)
      // console.log(value)
      // console.log(this.city)
      // let str = {
      //   sheng:'',
      //   shi:'',
      //   qu:''
      // }
      // this.city.forEach(item => {
      //   if(item.value == obj[0]){
      //     str.sheng = item.label
      //   }
      //   item.children.forEach(res => {
      //     if(res.value == obj[1]){
      //       str.shi = res.label
      //     }
      //     res.children.forEach(reslt => {
      //       if(reslt.value == obj[2]){
      //         str.qu = reslt.label
      //       }
      //     })
      //   })
      // });
      // // console.log('str',str);
      // // 赋值
      // value.componentValue = str.sheng + ' ' + str.shi + ' ' + str.qu
      // console.log(value.componentValue)

    },

    // 选中日期
    xuanzeriqi(value){
      console.log(value)
    },

    // 甲方选中印章
    active(it1,idx1,it){
      // console.log(it1);
      // console.log(idx1);
      // console.log(it)
      this.isShow = idx1;
      it.componentValue = it1.sealId;
    },

    // 乙方选中印章
    active1(sealitem,sealindex,it){
      // console.log(it1);
      // console.log(idx1);
      // console.log(it)
      console.log('123465')
      this.isShow1 = sealindex;
      it.componentValue = sealitem.sealId;
    },


  }

}
</script>

<style lang="scss" scoped>
.ageing{
  .ageing_b{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    margin-top: 15px;
  }

  .ageing_b_text_l{
    display: inline-block;
    width: 160px;
    font-size: 16px;
    font-weight: 400;
    color: #868792;
    text-align: right;
  }
  .ageing_b_text_r{
    margin-left: 15px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #050505;
  }
}

// 左右并行
.parallel:after{
  content: '';
  clear: both;
  display: block;
}
.parallel{
  .left{
    width: 30%;
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #050505;
    margin-top: 5px;
  }
  .right{
    width: 50%;
    float: left;
    margin-left: 15px;
  }
}

.right_img{
  width: 300px;
  height: 280px;
  padding: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

.xuanze{
  display: flex;
  align-items: center;
  //align-items: center;
  //justify-content: center;
  //padding: 10px;
  //border-radius: 50%;
}
.xuanzhong{
  //background: #00ff00;
}
</style>
